
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class MiscGameplay extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------

        // Methods -----------------------------------------------------------------------------------------------------

        // Data function -----------------------------------------------------------------------------------------------
        public data() {
            return {
                engineIconPathAndName:
                    require("@/assets/images/icon_engine.png"),

                screenshotArkhamShadow01:
                    require("@/assets/images/screenshot_arkham_shadow_01.png"),

                screenshotArkhamShadow02:
                    require("@/assets/images/screenshot_arkham_shadow_02.png"),
            };
        }
    }
